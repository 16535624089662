<template src="./changepassword.html">    
</template>
<style scoped src="./changepassword.css">
</style>
<script>
import { required, sameAs, minLength, maxLength } from 'vuelidate/lib/validators'
import HomeService from '@/services/HomeService'
import appConstants from '@/constants/AppConstants'
import modal from '@/components/modal/notification/modal'
import Loading from 'vue-loading-overlay'
// const passwordRegularExpression ='/^(?=.{8,})(?=.*[a-z])(?=.*[A-Z]).*$/'
export default {
    components : {
        modal, Loading
    },
    data() {
        return {   
            password : {
                current : '',
                new : '',
                confirm : ''
            },
            errorMessage : appConstants.formsMessages,
            alertObject: {
                errorCode: null,
                errorDescription: null,
                errorDetails: []
            },
            loaderVisible : false
        }
    },
    methods : {
        onSubmit(){
            this.$v.$touch()
            if(this.$v.$invalid) {
                return
            }
            this.loaderVisible = true
            HomeService.methods.ChangePassword(this.password.new)
            .then (() => {
                this.loaderVisible = false
                this.showAlert(1, 'New password has been updated.')
                this.onCancel()
            })
            .catch(err => {
                this.loaderVisible = false
                this.alertObject = err
                this.$bvModal.show(this.$refs.childModal.id)
            })
        },
        onCancel(){
            this.password.current = '',
            this.password.new = '',
            this.password.confirm = ''
            this.$v.$reset()
        },
        showAlert(code, description) {
            this.alertObject.errorCode = code
            this.alertObject.errorDescription = description
            this.alertObject.errorDetails = []
            this.$bvModal.show(this.$refs.childModal.id)
        } 
    },
    validations : {
        password : {
            current : {
                checkCurrenctPassword (pass) {
                return (
                    atob(localStorage.getItem('user-auth').split('Basic ')[1]).split(
                        ':'
                        )[1] === pass
                    )
                }
            },
            new : {
                required,
                minLength: minLength(8),
                maxLength: maxLength(20),                
                strongPassword (pass) {
                return (
                    /[a-z]/.test(pass) &&
                    /[A-Z]/.test(pass) &&
                    (/[0-9]/.test(pass) ||
                    /\W|_/.test(pass))
                )
                },
                // passwordRex:helpers.regex("alpha", new RegExp(passwordRegularExpression.slice(1, -1)))
            },
            confirm : {
               sameAsPassword: sameAs('new'),
            }
        }        
    }  
}
</script>